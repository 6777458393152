<template>
  <div>
    <el-page-header @back="dynamic" content="简介公告" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" disabled type="primary" @click="outerVisible = true; getDictionaryByModular(1)">新增</el-button>
      </el-button>
      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form ref="form" :model="noticeForm" label-width="80px" size="mini" label-position="top" class="pt-0">
          <el-form-item>
            <el-input v-model="noticeForm.noticeTitle" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item style="height: 368px">
            <quill-editor ref="text" v-model="noticeForm.notice" class="myQuillEditor" :options="editorOption" />
          </el-form-item>
          <el-form-item>
            <el-select v-model="noticeForm.noticeType" placeholder="模块" style="width: 100px;margin-right: 20px">
              <el-option
                  v-for="item in dictionaryData"
                  :key="item.value"
                  :label="item.dictionaryName"
                  :value="item.value">
                <span>{{ item.dictionaryName }}</span>
              </el-option>
            </el-select>
            <el-radio-group v-model="noticeForm.isOpen">
              <el-radio-button :label="0">私有</el-radio-button>
              <el-radio-button :label="1">公开</el-radio-button>
            </el-radio-group>
            <el-button @click="outerVisible = false;clearNoticeForm()" size="mini" style="margin-left: 50px">取 消</el-button>
            <el-button type="primary" size="mini" @click="editNotice();">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
    <el-table
        :data="noticeData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="noticeTitle"
          label="标题">
        <template slot-scope="scopeNews">
          <el-link @click="onToNewsReader(scopeNews.row.noticeId)">{{scopeNews.row.noticeTitle}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
          prop="userId"
          label="创建人"
          width="100">
      </el-table-column>
      <el-table-column
          prop="hits"
          label="浏览量"
          width="70">
      </el-table-column>
      <el-table-column
          prop="isOpen"
          label="显示"
          width="48">
        <template slot-scope="scopeOpen">
          <el-tag v-if="(scopeOpen.row.isOpen === 0)"  type="danger" size="mini">私有</el-tag>
          <el-tag v-if="(scopeOpen.row.isOpen === 1)" size="mini">公开</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="noticeType"
          label="显示"
          width="70">
        <template slot-scope="scopeOpen">
          <el-tag v-if="(scopeOpen.row.noticeType === 1)"  type="danger" size="mini">中心简介</el-tag>
          <el-tag v-if="(scopeOpen.row.noticeType === 2)" size="mini">新闻公告</el-tag>
          <el-tag v-if="(scopeOpen.row.noticeType === 3)" type="warning" size="mini">中心领导</el-tag>
          <el-tag v-if="(scopeOpen.row.noticeType === 4)" type="info" size="mini">职能部门</el-tag>
          <el-tag v-if="(scopeOpen.row.noticeType === 5)" type="success" size="mini">领导讲话</el-tag>
          <el-tag v-if="(scopeOpen.row.noticeType === 6)" type="info" size="mini">秘书处</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="upDate"
          label="更新日期"
          width="160">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="50">
        <template slot-scope="scopeNoticeDel">
<!--          <el-button-->
<!--              @click="deleteNotice(scopeNoticeDel.row.noticeId)"-->
<!--              type="text"-->
<!--              size="small">-->
<!--            移除-->
<!--          </el-button>-->
          <el-button
              @click="getNoticeById(scopeNoticeDel.row.noticeId);outerVisible=true; getDictionaryByModular(1)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
export default {
  name: "notice",
  components: {
    quillEditor
  },
  data() {
    return {
      outerVisible: false,
      editorOption: {},
      formLabelWidth: '120px',
      noticeData: [],
      noticeForm:{
        noticeId: '',
        noticeTitle: '',
        notice: '',
        isOpen: 1,
        noticeType: ''
      },
      dictionaryData: []
    }
  },
  mounted() {
    this.getNoticeList()
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    editNotice() {
      this.$confirm('是否立即提交?' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        var noticeUrl = '/api/notice/insertNotice';
        var noticeMethod = 'post';
        if (this.noticeForm.noticeId != null || this.noticeForm.noticeId != '') {
          noticeUrl = '/api/notice/editNotice';
          noticeMethod = 'put'
        }
        this.$http({
          method: noticeMethod,
          url: noticeUrl,
          data: this.noticeForm
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.noticeForm.noticeTitle = ''
          this.noticeForm.notice = ''
          this.outerVisible = false
          this.getNoticeList()
        }).catch(()=>{
          this.$message({
            type: 'error',
            message: "系统异常"
          })
        })
      }).catch(() => {
      });
    },
    getNoticeList() {
      this.$http({
        method: 'get',
        url: '/api/notice/getNoticeList'
      }).then((res) =>{
        this.noticeData = res.data.data
      })
    },
    getNoticeById(noticeId) {
      this.$http({
        method: 'get',
        url: '/api/notice/getNoticeById/' + noticeId,
      }).then((res)=>{
        this.noticeForm = res.data.data
        console.log(this.noticeForm)
      })
    },
    onToNewsReader(newsId) {
      this.$router.push({
        path: '/reader/',
        query: {id: newsId, type: 2}
      })
    },
    clearNoticeForm() {
      this.noticeForm.noticeTitle ='';
      this.noticeForm.notice ='';
      this.noticeForm.noticeType ='';
      this.noticeForm.noticeId ='';
    },
    getDictionaryByModular(modular) {
      this.$http({
        method: 'get',
        url: '/api/dictionary/getDictionaryByModular/' + modular
      }).then((res) =>{
        this.dictionaryData = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
.myQuillEditor {
  height: 300px;
}
</style>